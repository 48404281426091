<template>
  <div class="col1">
    <div class="crow">
      <div class="ctitle">Ir para Lote:</div>
      <div class="cvalue">Ir</div>
    </div>
    <div class="crow">
      <div class="ctitle">Lote Atual:</div>
      <div class="cvalue">Ir</div>
    </div>
    <div class="crow m-t-xxl">
      <div class="ctitle">Previsão:</div>
      <div class="cvalue">17:53</div>
    </div>
    <div class="crow">
      <div class="ctitle">Online</div>
      <div class="cvalue">Em breve</div>
    </div>

    <aprovar class="m-t-xxl" v-for="lote in pendencias" :key="lote.id" :lote="lote" @atualizado="findPendencias" @contraproposta="contraproposta" />
    <contraproposta ref="contraproposta" />
  </div>
</template>

<script>
import ColunaMixin from './colunaMixin'
import Aprovar from "@/components/leiloes/components/monitor-comitente/acompanhamento/Aprovar"
import {pendenciasAprovacao} from "@/domain/comitentes/services"
import Contraproposta from "@/components/leiloes/components/monitor-comitente/acompanhamento/Contraproposta"
export default {
  name: "Coluna1",
  mixins: [ColunaMixin],
  components: {Contraproposta, Aprovar},
  data() {
    return {
      pendencias: null
    }
  },
  mounted() {
    this.findPendencias()
  },
  watch: {
    lote (lt) {
      console.log('Mudou LOTE')
      this.findPendencias()
    }
  },
  methods: {
    findPendencias() {
      pendenciasAprovacao(this.leilao.id)
          .then(({data}) => {
            this.pendencias = data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    contraproposta (lote) {
      console.log('Contraproposta ', lote)
      this.$refs.contraproposta.digitarLance(lote)
    }
  }
}
</script>
