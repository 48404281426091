<template>
    <u-dialog
        v-model="digitar"
        @cancel="digitarLanceCancel"
        @ok="digitarLanceConfirm"
    >
      <span slot="title">Informe o valor mínimo aceitável para o lote {{lote.numero}}</span>

      <!--<span slot="message">What is your superhero of choice?</span>-->

      <div slot="body">
        <u-field
            icon="attach_money"
            label="Proposta de ajuste de lance:"
            :label-width="3"
        >
          <u-input ref="defInput" autofocus v-model="digitarLanceValor" @keydown.prevent.enter="digitarLanceConfirm" class="no-shortkey" v-money="money" />
        </u-field>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Enviar proposta" @click="props.ok" />
      </template>
    </u-dialog>
</template>

<script>
import {UDialog, UField, UInput} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {sendMessage} from "@/domain/comitentes/services"

export default {
  name: 'Contraproposta',
  components: {UDialog, UInput, UField},
  directives: {money: VMoney},
  data () {
    return {
      money: REAL_BRL,
      digitar: false,
      digitarLanceValor: null,
      lote: {}
    }
  },
  methods: {
    sendMessage (msg) {
      this.aprovando = true
      this.$uloc.loading.show()
      return sendMessage(msg)
          .then(response => {
            this.$uloc.loading.hide()
            this.aprovando = false
            this.loteActions = true
            this.$uloc.notify({
              color: 'positive',
              message: `Operação efetuada com sucesso`
            })
          })
          .catch(error => {
            this.alertApiError(error)
            this.aprovando = false
            this.$uloc.loading.hide()
            this.loteActions = false
          })
    },
    digitarLance (lote) {
      this.lote = lote
      // this.resetAlteracaoLance()
      this.digitar = true
      window.setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.defInput.focus()
        })
      }, 400)
    },
    digitarLanceConfirm () {
      let msg = {
        lote: this.lote.id,
        tipo: 105,
        mensagem: `Lote ${this.lote.numero} faço contraproposta de R$ ${this.digitarLanceValor}.`
      }
      this.sendMessage(msg)
      this.resetAlteracaoLance()
    },
    digitarLanceCancel () {
      this.resetAlteracaoLance()
    },
    resetAlteracaoLance () {
      this.digitar = false
      this.digitarLanceValor = null
    }
  }
}
</script>
