<script>
import Controlador from '@/components/leiloes/components/controlador/ControladorV2'
import {pendenciasAprovacao} from "@/domain/comitentes/services"
import {STATUS_CONDICIONAL} from "@/domain/leiloes/helpers/LoteStatus"
import Coluna1 from "@/components/leiloes/components/monitor-comitente/acompanhamento2/Coluna1";
import Coluna2 from "@/components/leiloes/components/monitor-comitente/acompanhamento2/Coluna2";
import Coluna3 from "@/components/leiloes/components/monitor-comitente/acompanhamento2/Coluna3";

export default {
  name: 'AcompanhamentoComitente2',
  mixins: [Controlador],
  components: {
    Coluna3,
    Coluna2,
    Coluna1
  },
  data() {
    return {
      visibledKeyboardStats: true,
      pendencias: null
    }
  },
  computed: {},
  mounted() {
    this.page.setActive('aco2')
    this.load()
    this.findPendencias()
    let chat = document.createElement('script')
    const loc = location.hostname
    let url = 'https://code.tidio.co/eijaeffsibycl81t5rxmpb1cgvlnlfwk.js'
    if (loc.indexOf('buaizleiloes.com.br') > -1) {
      url = 'https://code.tidio.co/orqhlmegbvtlphkakkgud43wnhpmxhgv.js'
    }
    chat.setAttribute('src', url)
    document.head.appendChild(chat)
  },
  destroyed() {
  },
  methods: {
    /**
     * @TODO: RECEBER VIA WEBSOCKET
     */
    __mudaLote(data) {
      console.log('Muda lote', data)
      if (!this.isLeilaoComunication(data)) return
      this.findPendencias()
      if (typeof this.seguirLeilao !== 'undefined' && this.seguirLeilao === false) return
      this.leilao = Object.assign({}, this.leilao, {pregaoAtivo: data.pregao})
      if (this.lote) {
        this.loteAnterior = this.lote
      }
      this.lote = data.pregao.lote
      if (this.lote.status === 2) {
        this.ativaTimer()
      }
    },
    __statusLote(data) {
      if (!this.isLoteComunication(data.lote.id)) return
      this.lote = Object.assign({}, this.lote, data.lote)
      if (data.lote.status !== 2) {
        this.desativaTimer()
      } else {
        this.$nextTick(() => {
          this.ativaTimer()
        })
      }
      if (data.lote.status === STATUS_CONDICIONAL) {
        this.findPendencias()
      }
    },
    findPendencias() {
      pendenciasAprovacao(this.leilao.id)
          .then(({data}) => {
            this.pendencias = data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    contraproposta (lote) {
      console.log('Contraproposta ', lote)
      this.$refs.contraproposta.digitarLance(lote)
    }
  },
  meta: {
    title: 'Acompanhamento do Comitente',
    name: 'Acompanhamento do Comitente'
  }
}
</script>

<template>
  <div v-if="!loading" class="leiloeiro acomitente2" :class="['show-keyboard']">
    <!--    <stats-sup :leilao="leilao" />-->
      <coluna1 :leilao="leilao" :lote="lote" />
      <coluna2 :leilao="leilao" :lote="lote" />
      <coluna3 :leilao="leilao" :lote="lote" />
  </div>
  <div class="controlador-v2 flex flex-center items-center column h-full no-select text-white"
       style="justify-content: center !important" v-else>
    <div class="m-b-xs">
      <u-spinner-uloc/>
    </div>
    Carregando a aplicação
  </div>
</template>

<style src="../../assets/acompanhamento-comitente2.styl" lang="stylus"/>
