<template>
  <div class="col3">
    <ul class="stats">
      <stats-tab-item label="Vendidos" :count="e.vendidos" :value="pctVendidos" color="positive" />
      <stats-tab-item label="Condicionais" :count="e.condicional" :value="pctCondicional" color="warning" />
      <stats-tab-item label="Não vendidos" :count="e.naoVendidos" :value="pctNaoVendidos" color="negative" />
      <stats-tab-item label="Para leiloar / Saldo" :count="e.paraLeiloar" :value="pctParaLeiloar" color="info" />
      <stats-tab-item label="Total em vendas" count="" :value="totalVendidoValor" color="t text-positive" />
      <stats-tab-item label="Total condicionais" count="" :value="totalVCondicionalValor" color="t text-warning" />
    </ul>
  </div>
</template>

<script>
import ColunaMixin from './colunaMixin'
import Estatisticas from '../../controlador/include-v2/Estatisticas'
import StatsTabItem from "@/components/leiloes/components/monitor-comitente/acompanhamento2/StatsTabItem";
export default {
  mixins: [ColunaMixin, Estatisticas],
  components: {StatsTabItem},
  name: "Coluna3"
}
</script>
