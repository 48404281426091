<template>
  <div class="pendencia-aprovacao">
    <div class="stats-tab">
      <div class="stats-tab-header">
        CONDICIONAL LOTE {{ lote.numero }}
      </div>
      <ul>
        <li>
          <div class="full-width">
            <div class="comitente" v-if="lote.bem.comitente">
              {{ lote.bem.comitente.pessoa.name }}
            </div>
            <div class="info-avaliacao">
              <div class="lbl">
                Avaliação sobre o lance atual:
              </div>
              <div class="values">
                <div>
                  <div class="title">Mínimo</div>
                  <div class="val">R$ {{ lote.valorMinimo|moeda }}</div>
                </div>
                <div>
                  <div class="title">Valor do melhor lance</div>
                  <div class="val" :class="pctValorMinimo.type">
                    <span>R$ {{ lote.arremate.lance.valor|moeda }}</span> {{ pctValorMinimo.value }}.
                  </div>
                  <div class="m-t-n-xs"><small>Diferença: R$
                    {{ lote.valorMinimo - lote.arremate.lance.valor|moeda }}</small></div>
                </div>
              </div>
            </div>
            <div class="flex row content-between full-width">
              <u-btn @click="atualizar(1)" :loading="aprovando" color="positive" label="Aprovar condicional"
                     class="full-width block"/>
              <u-btn @click="atualizar(2)" :loading="aprovando" color="negative" label="Recusar condicional"
                     class="full-width block"/>
              <u-btn @click="contraproposta" :loading="aprovando" color="yellow" dense flat no-caps label="Realizar contraproposta"
                     class="full-width block"/>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import PctMixin from '../../tela-leiloeiro/mixin/pct-mixin'
import {aprovarReprovar} from "@/domain/comitentes/services"

export default {
  name: "Aprovar",
  props: ['lote'],
  mixins: [PctMixin],
  data() {
    return {
      controlador: this,
      aprovando: false
    }
  },
  computed: {
    ultimoLance() {
      return this.lote.arremate.lance
    }
  },
  methods: {
    atualizar(s) {
      this.aprovando = true
      aprovarReprovar(this.lote.id, s)
          .then(({data}) => {
            this.aprovando = false
            this.$emit('atualizado', this.lote)
            this.$uloc.notify({color: 'positive', message: `Operação realizada com sucesso!`})
            console.log(data)
          })
          .catch(error => {
            this.aprovando = false
            this.alertApiError(error)
          })
    },
    contraproposta () {
      this.$emit('contraproposta', this.lote)
    }
  }
}
</script>

<style lang="stylus" scoped>
.pendencia-aprovacao {
  color #FFFFFF
  // margin-top 10px

  .comitente {
    font-size 12px
  }

  .info-avaliacao {
    margin-top 10px

    .lbl {
      margin-bottom 10px
    }

    .title {
      font-weight 500
      margin-bottom 4px
    }

    .val {
      margin-bottom 10px
      font-size 16px
      font-weight bold

      &.positive {
        color: #4CD964
      }

      &.negative {
        color: #F53B30
      }
    }
  }

  .u-btn {
    margin-top 8px
    width 100% !important
  }
}
</style>
