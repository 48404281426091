<template>
  <div class="col2">
    <div class="lote-info">
      <div class="lote-img-container">
        <div class="lote-img" v-if="lote.bem.image" :class="['lote-img-status' + lote.status]"
             :style="{backgroundImage: 'url('+lote.bem.image.min.url || lote.bem.image.url+')'}"></div>
        <div class="lote-img" v-else>
          <div class="noimg h-full w-full flex flex-center items-center justify-center column text-grey-6 no-select">
            <div>
              <u-icon name="image"/>
            </div>
            Sem imagem
          </div>
        </div>
      </div>
      <div class="lote-details">
        <div class="lote-num">Lote {{ controlador.lote.numero }}</div>
        <div class="lote-title">
          {{
            String(lote.bem.siteTitulo).length > 130 ? String(lote.bem.siteTitulo).substring(0, 130) + '...' : String(lote.bem.siteTitulo)
          }}
        </div>
        <div class="lote-desc" :title="lote.bem.siteDescricao">
          {{
            String(lote.bem.siteDescricao).length > 130 ? String(lote.bem.siteDescricao).substring(0, 130) + '...' :
                String(lote.bem.siteDescricao)
          }}
        </div>
      </div>
    </div>
    <div class="lote-controle">
      <div class="valores">
        <div class="cvalor">
          <div class="ctitle">Valor mínimo</div>
          <div class="cvalue">R$ {{ lote.valorMinimo| moeda }}</div> <!-- @TODO: MUDAR COR AO ATINGIR -->
        </div>
        <div class="cvalor">
          <div class="ctitle">Valor avaliação</div>
          <div class="cvalue">R$ {{ lote.valorAvaliacao| moeda }}</div>
        </div>
        <div class="cvalor">
          <div class="ctitle">Valor Mercado/Fipe</div>
          <div class="cvalue">R$ {{ lote.valorMercado| moeda }}</div>
        </div>
        <div class="cvalor lance">
          <div class="ctitle">Lance atual</div>
          <div v-if="controlador.ultimoLance" class="cvalue stats-input valor-atual val"
               :class="{novoLance: hasNovoLance}">R$
            {{ controlador.valorAtual | moeda }}
          </div>
          <div v-else class="cvalue stats-input valor-atual val">-</div>
        </div>
      </div>
      <div class="acoes">
        <div class="acao-btn">
          <button :disabled="loteActions" @click="atualizarLote(1)" class="btn-liberado">Liberado</button>
        </div>
        <div class="acao-btn">
          <button :disabled="loteActions" class="btn-minimo" @click="atualizarLote(3)">Mínimo</button>
        </div>
        <div class="acao-btn">
          <button :disabled="loteActions" @click="atualizarLote(2)" class="btn-recusado">Recusado</button>
        </div>
        <div class="acao-btn">
          <button :disabled="loteActions" class="btn-condicional"  @click="atualizarLote(4)">Condicional</button>
        </div>
        <div class="acao-btn oferta">
          <input @keydown.enter="digitarLanceConfirm" v-model="oferta" v-money="money" type="text"/>
          <button :disabled="loteActions" @click="digitarLanceConfirm" class="btn-oferta">Oferta</button>
        </div>
      </div>
    </div>
    <div class="lote-transmissao">
      <div class="live">
        <div class="leilao-live flex flex-center">
          <iframe class="m-l-xs" v-if="leilao.video" height="100%"
                  :src="leilao.video + '?controls=0&autoplay=1&loop=1&rel=0&showinfo=0'"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; loop"
                  allowfullscreen></iframe>
          <div v-else class="flex flex-center">Aguardando transmissão</div>
        </div>
      </div>
      <div class="dados-lote">
        <div class="valores">
          <div class="cvalor">
            <div class="ctitle">Cronômetro</div>
            <div class="cvalue">{{ controlador.timerPregaoFormatado }}</div>
          </div>
          <div class="cvalor">
            <div class="ctitle">Lance atual</div>
            <div class="cvalue">
              <div v-if="controlador.ultimoLance" class="val">
                {{ controlador.ultimoLance.autor.apelido }}
                <span>{{ controlador.lanceLocalidade }}</span>
              </div>
              <div class="val" v-else>
                -
              </div>
            </div>
          </div>
          <div class="cvalor">
            <div class="ctitle">Lances neste lote</div>
            <div class="cvalue">{{ lote.lances && lote.lances.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColunaMixin from './colunaMixin'
import pctMixin from "@/components/leiloes/components/tela-leiloeiro/mixin/pct-mixin"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import {sendMessage} from "@/domain/comitentes/services"

const TIPO_COMUNICACAO = 0
const TIPO_APROVACAO_LOTE = 101
const TIPO_VENDA_SOMENTE_MINIMO = 102
const TIPO_REPROVACAO_LOTE = 103
const TIPO_CONDICIONAL = 104
const TIPO_OFERTA = 105

export default {
  directives: {money: VMoney},
  mixins: [ColunaMixin, pctMixin],
  name: "Coluna2",
  data() {
    return {
      money: REAL_BRL,
      oferta: null,
      hasNovoLance: false,
      aprovando: false,
      loteActions: false
    }
  },
  watch: {
    lote () {
      this.loteActions = false
    }
  },
  methods: {
    atualizarLote (s) {
      let msg = {
        lote: this.lote.id,
        tipo: 0,
        mensagem: null
      }
      if (s === 1){
        msg.tipo = TIPO_APROVACAO_LOTE
        msg.mensagem = `Lote ${this.lote.numero} liberado.`
      } else if(s === 2) {
        msg.tipo = TIPO_REPROVACAO_LOTE
        msg.mensagem = `Lote ${this.lote.numero} RECUSADO.`
      } else if(s === 3) {
        msg.tipo = TIPO_VENDA_SOMENTE_MINIMO
        msg.mensagem = `Lote ${this.lote.numero} aceito somente MÍNIMO.`
      } else if(s === 4) {
        msg.tipo = TIPO_CONDICIONAL
        msg.mensagem = `Lote ${this.lote.numero} manter condicional.`
      }
      this.sendMessage(msg)
    },
    sendMessage (msg) {
      this.aprovando = true
      this.$uloc.loading.show()
      return sendMessage(msg)
          .then(response => {
            this.$uloc.loading.hide()
            this.aprovando = false
            this.loteActions = true
            this.$uloc.notify({
              color: 'positive',
              message: `Operação efetuada com sucesso`
            })
          })
          .catch(error => {
            this.alertApiError(error)
            this.aprovando = false
            this.$uloc.loading.hide()
            this.loteActions = false
          })
    },
    digitarLanceConfirm() {
      const proposta = convertRealToMoney(this.oferta)
      let msg = {
        lote: this.lote.id,
        tipo: TIPO_OFERTA,
        mensagem: `Lote ${this.lote.numero} faço contraproposta de R$ ${this.oferta}.`
      }
      this.sendMessage(msg)
      this.resetAlteracaoLance()
    },
    digitarLanceCancel() {
      this.resetAlteracaoLance()
    },
    resetAlteracaoLance() {
      this.oferta = null
    },
    manterCondicional () {
      this.$uloc.loading.show()
      setTimeout(() => {
        this.$uloc.loading.hide()
        this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Condicional mantida'})
      }, 1000)
    }
  }
}
</script>
